body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  background-color: #010322;
  color: #888dcf;
  overflow-x: hidden !important;
}
.adminpreviliges .rdt_TableCell > div {
  text-overflow: unset !important;
  white-space: unset !important;
}
.rdt_TableCell div:first-child,
.rdt_TableCol_Sortable div {
  white-space: normal !important;
  text-overflow: unset;
  padding-right: 20px;
  width: fit-content;
  min-width: fit-content;
}
a {
  color: #0098df;
  text-decoration: none;
}
a:hover {
  color: #0098df;
  text-decoration: none;
}
/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}
*::-webkit-scrollbar-thumb {
  background-color: #01fa00;
}
img.brand_logo {
  width: 220px;
}
.navbar-sticky--moved-up {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    112deg,
    rgba(6, 8, 52, 1) 1.31%,
    rgba(26, 29, 78, 1) 58.6%,
    rgba(2, 7, 57, 1) 112.43%
  ) !important;
  margin-top: -100px;
  -webkit-box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
}
.navbar-sticky--on {
  margin-top: 0;
}
.navbar-sticky--transitioned {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
/* Dashboard Css */
.apppage_wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.app_page {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
}
.app_header {
  z-index: 100;
  position: fixed;
  left: 250px;
  right: 0;
  top: 0;
  background: transparent;
  transition: none;
  display: flex;
  align-items: stretch;
}
.app_sidebar {
  background: linear-gradient(
    112deg,
    rgba(6, 8, 52, 0.48) 1.31%,
    rgba(26, 29, 78, 0.5) 58.6%,
    rgba(2, 7, 57, 0.37) 112.43%
  );
  border: 1px solid #1a1d4e;
  border-right: 0;
  width: 250px;
  position: fixed;
  z-index: 105;
  top: 0;
  bottom: 0;
  left: 0;
  transition: width 0.3s ease;
}
.page_wrapper {
  margin-left: 250px;
  margin-top: 120px;
  transition: margin-left 0.3s ease, margin-right 0.3s ease;
  display: flex;
  flex: 1 auto;
  min-width: 0;
  flex-direction: column;
}
.page_content {
  transition: margin 0.3s ease;
  display: flex;
  flex: 1 auto;
  min-width: 0;
  flex-direction: column;
  min-height: 100vh;
  padding-bottom: 100px;
}
.app_sidebar_logo {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #1a1d4e;
}
ul.sidebar_menu {
  padding-left: 0;
  padding-top: 15px;
}
ul.sidebar_menu li + li {
  margin-top: 22px;
}
ul.sidebar_menu li a.nav-link {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.8px;
  text-decoration: none;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 0 !important;
  position: relative;
}
ul.sidebar_menu li a.nav-link.active,
ul.sidebar_menu li a.nav-link:hover {
  background: #010322;
}
ul.sidebar_menu li svg {
  margin-right: 20px;
  width: 18px;
  fill: #fff;
}
ul.sidebar_menu li a.nav-link.btn-toggle::after {
  width: 1.25em;
  line-height: 0;
  content: url("../src/assets/images/menu_caret_icon.svg");
  transition: transform 0.35s ease;
  transform-origin: 0.5em 50%;
  position: absolute;
  right: 10px;
  filter: invert(1);
}
ul.sidebar_menu li a.nav-link.btn-toggle[aria-expanded="true"]::after {
  transform: rotate(90deg);
}
.sidebar_submenu {
  padding-left: 54px;
  margin-top: 12px;
}
.sidebar_submenu ul li a {
  position: relative;
  font-size: 14px;
  color: #fff !important;
  transition: 0.2s all;
}
.sidebar_submenu ul li a:hover {
  color: #01fa00 !important;
}
.sidebar_submenu ul li a::before {
  content: "";
  background: #fff;
  width: 5px;
  height: 5px;
  position: absolute;
  border-radius: 50%;
  top: 7px;
  left: -15px;
}
h2.page_title {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, #0098df -0.01%, #01fa00);
  background-clip: text;
  -webkit-background-clip: text;
  display: inline-block;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 2.4px;
  margin-bottom: 0;
  text-transform: capitalize;
}
.usericon_dropdown span {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.8px;
  margin-left: 20px;
}
.topnavbar_right .dropdown-toggle::after {
  content: "";
  background: url("../src/assets/images/caret_icon.svg") no-repeat;
  border: 0;
  width: 15px;
  height: 10px;
  vertical-align: 0;
  margin-left: 15px;
}
.usericon_dropdown .dropdown-menu-dark {
  background: #0c0f33;
  /* display: block;
  opacity: 0; */
  top: 100% !important;
  transform: translateY(20px) !important;
  transition: all 0.5s;
  min-width: 200px;
}
.usericon_dropdown .dropdown-menu-dark.show {
  transform: translateY(0) !important;
  /* opacity: 1; */
}
.usericon_dropdown .dropdown-menu-dark .dropdown-item.active,
.usericon_dropdown .dropdown-menu-dark .dropdown-item:active,
.usericon_dropdown .dropdown-menu-dark .dropdown-item:hover {
  background-color: #111111;
}
.widget_panel {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 15px 10px;
}
.widget_panel_single {
  border-radius: 8px;
  background: linear-gradient(
    112deg,
    rgba(6, 8, 52, 0.48) 1.31%,
    rgba(26, 29, 78, 0.5) 58.6%,
    rgba(2, 7, 57, 0.37) 112.43%
  );
  border: 1px solid #1a1d4e;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 6, 0.15);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  gap: 7px;
}
.widget_panel_single img {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 35%;
}
.widget_panel_single_inner {
  position: relative;
}
.cursor-pointer {
  cursor: pointer;
}
.widget_panel_single h6 {
  font-size: 16px;
  font-weight: 400;
  line-height: 175%;
  color: #8a92a6;
  margin-bottom: 0;
  text-transform: capitalize;
}
.widget_panel_single h5 {
  font-size: 16px;
  font-weight: 500;
  line-height: 175%;
  color: #d3d3d3;
  margin-bottom: 0;
}
h2.dash_inner_title {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.total_visits_panel {
  border-radius: 8px;
  background: #201f21;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 6, 0.15);
  display: flex;
  padding: 24px;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 15px;
}
.total_visits_panel h2 {
  color: #fff;
  font-size: 28px;
  font-weight: 500;
  line-height: 130%;
  margin-bottom: 0;
}
.total_visits_panel p {
  color: #5c6ca5;
  font-size: 16px;
  font-weight: 400;
  line-height: 175%;
  margin-bottom: 0;
}
.total_visits_panel > div {
  text-align: center;
}
.market_panel {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 20px;
}
.market_panel_single {
  border-radius: 8px;
  background: #181717;
  display: flex;
  padding: 20px 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  flex-shrink: 0;
}
.market_panel_single > div img {
  width: 33px;
  height: 33px;
}
.market_panel_single > div {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
.market_panel_single > div span {
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.8px;
}
.market_panel_single h6 {
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
  letter-spacing: 1.8px;
}
.market_panel_single h5 {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.4px;
}
.red_text {
  color: #da1818;
}
.green_text {
  color: #32a953;
}
.dashbox {
  border-radius: 8px;
  background: #201f21;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 6, 0.15);
  padding: 15px;
  /* margin-bottom: 15px; */
}
.dashbox_fullheight {
  height: 100%;
}
.rdt_TableCol,
.rdt_TableCell {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.4px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.rdt_TableCell {
  color: #fff !important;
  font-weight: 400;
}
.sale_order_bg {
  background: rgba(26, 160, 83, 0.36);
  display: inline-block;
  padding: 3px 5px;
}
.align_right {
  text-align: right;
  width: 100%;
}
.dashtitle_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.dashtitle_flex a {
  font-size: 14px;
}
.fa-rotate-45 {
  transform: rotate(45deg);
}
.total_fund_value h5 {
  color: #adadad;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.6px;
  margin: 0;
}
.total_fund_value h3 {
  color: #fff;
  font-size: 30px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 3px;
  margin: 0;
}
.total_fund_value h4 {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.34px;
  margin: 0;
}
.total_fund_value h6 {
  color: #fdfdff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.154px;
  margin: 0;
}
.total_fund_value > div {
  border-bottom: 1px solid #363536;
  padding: 20px 15px;
}
.total_fund_value > div:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
.sales_chart_info {
  display: flex;
  align-items: center;
  gap: 50px;
}
.sales_chart_info h5 {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.154px;
}
.sales_chart_info h3 {
  color: #e3d8d8;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.34px;
}
.sales_chart_info p {
  color: #fdfcfc;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 0;
}
.sales_chart_info select {
  background: transparent url("../src/assets/images/select_dropdown.png")
    no-repeat;
  background-position: right 0.75rem center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.154px;
  color: #fff;
  border: 0;
  box-shadow: none !important;
  padding-left: 3px;
}
.sales_chart_info select option {
  background: #111;
}
.sales_chart_info h4 {
  color: #ffe500;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.32px;
}
.sales_chart_header {
  border-bottom: 1px solid #363536;
  padding-bottom: 20px;
  margin-bottom: 15px;
}
.sales_chart_header > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.custom_datepicker .react-datepicker-wrapper {
  position: relative;
  z-index: 1;
}
.custom_datepicker .react-datepicker__input-container input {
  border: 1px solid #545354;
  border-radius: 4px;
  background: transparent;
  padding: 4px 10px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #fff;
  outline: none !important;
  width: 700px;
  height: 40px;
}
.custom_datepicker .react-datepicker__input-container input:focus {
  border: 2px solid #f3ba2f;
}
.custom_datepicker .react-datepicker-wrapper::before {
  content: "";
  background: url("../src/assets/images/calendar_icon.png") no-repeat right
    center;
  width: 14px;
  height: 14px;
  position: absolute;
  right: 10px;
  top: 12px;
  z-index: -1;
}
.react-datepicker {
  background-color: #161515 !important;
  color: #fff !important;
  border: 1px solid #161515 !important;
}
.react-datepicker__header {
  background-color: #161515 !important;
  border-bottom: 1px solid #363536 !important;
  color: #fff !important;
}
.react-datepicker__time-container .react-datepicker__time {
  background-color: #161515 !important;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #fff !important;
}
.react-datepicker__time-container {
  border-left: 1px solid #363536 !important;
}
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border-bottom-color: #161515 !important;
}
.react-datepicker__navigation {
  top: 8px !important;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: #302f35 !important;
  color: #fff !important;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #151417 !important;
  color: #ffffff !important;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background-color: #302f35 !important;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #f3ba2f !important;
  color: #111 !important;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #f3ba2f !important;
  color: #111 !important;
}
.react-datepicker-popper {
  z-index: 2 !important;
}
.topnavbar_right {
  display: flex;
  align-items: center;
  gap: 30px;
}
.topnavbar_right i.fa-bars,
.app_sidebar i.close_icon {
  display: none;
}
.page_content .container-fluid,
.app_header .container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}
.admin_profile img {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}
.top_filter_wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
  gap: 15px;
}
.top_filter_wrapper label {
  text-transform: capitalize;
}
.MuiCheckbox-root {
  color: #f3ba2f !important;
}
.custom_toggle_switch input[type="checkbox"] {
  display: none;
}
.custom_toggle_switch input[type="checkbox"] + label {
  position: relative;
  display: inline-block;
  padding: 0.5rem 1rem;
  text-align: right;
  border-radius: 22%/50%;
  box-sizing: border-box;
  background: #12121a;
  background: linear-gradient(to bottom, #12121a 1%, #12121a 100%);
  box-shadow: inset 0 5px 2px 0 #000000, inset 0 0 0 1px #363844;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
}
.custom_toggle_switch .handler {
  position: absolute;
  top: 0;
  left: -1%;
  display: block;
  padding: 21.0782%;
  border-radius: 50%;
  box-shadow: inset 0 5px 2px 0 #363844, inset 0 0 0 1px #363844,
    0 2px 2px 0 #363844;
  background: #363844;
  background: linear-gradient(to bottom, #363844 0%, #363844 100%);
  transition: all 0.4s;
  z-index: 3;
}
.custom_toggle_switch .handler-inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  width: 56%;
  height: 56%;
  border-radius: 50%;
  margin: auto;
  box-shadow: inset 0 2px 2px 0 #363844, inset 0 0 0 1px #2d2d2d,
    0 2px 0 0 #000000;
  background: #61c428;
  background: linear-gradient(to bottom, #61c428 1%, #1a561a 100%);
  z-index: 4;
}
.custom_toggle_switch .toggle_text {
  position: relative;
  left: -10%;
  color: white;
  opacity: 0;
  transition: all 0.6s;
  z-index: 2;
}
.custom_toggle_switch .toggle_bg {
  position: absolute;
  top: 0;
  left: -100%;
  display: inline-block;
  width: 100%;
  height: 100%;
  background: #23641b;
  background: linear-gradient(to bottom, #23641b 1%, #50a627 100%);
  transition: all 0.4s ease-out;
  opacity: 0.99;
  z-index: 1;
}
.custom_toggle_switch input[type="checkbox"]:checked + label > .handler {
  left: 59%;
}
.custom_toggle_switch input[type="checkbox"]:checked + label > .toggle_text {
  opacity: 1;
}
.custom_toggle_switch input[type="checkbox"]:checked + label > .toggle_bg {
  left: 0;
}
.profit_activity p {
  color: #5c6ca5;
  font-size: 16px;
  font-weight: 400;
  line-height: 175%;
}
.profit_activity {
  display: flex;
  align-items: center;
  gap: 15px;
}
.profit_activity_list {
  list-style: none;
  padding: 0;
  margin: 30px 0 0;
  height: 650px;
  overflow-y: auto;
}
.profit_activity_list li {
  position: relative;
  padding-left: 50px;
  padding-bottom: 30px;
  z-index: 1;
}
.profit_activity_list li::before {
  content: "";
  background-color: #201f21;
  border: 2px solid #3a57e8;
  border-radius: 100%;
  width: 21px;
  height: 21px;
  position: absolute;
  left: 0;
  top: 7px;
}
.profit_activity_list li::after {
  content: "";
  background: #5c6ca5;
  width: 1px;
  height: 100%;
  position: absolute;
  left: 10.5px;
  top: 28px;
  z-index: -1;
}
.profit_activity_list li:last-child::after {
  display: none;
}
.profit_activity_list li p {
  color: #d3d3d3;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%;
  margin-bottom: 0;
}
.profit_activity_list li span {
  color: #5c6ca5;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%;
}
.footer {
  background: #0a0e40;
  padding: 15px 30px;
}
.footer_panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer_menu_links {
  list-style: none;
  display: flex;
  gap: 30px;
  padding: 0;
  margin: 0;
}
.footer_menu_links li a {
  color: #8a92a6;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  transition: 0.2s all;
}
.footer_menu_links li a:hover,
.footer_panel_left p a:hover {
  color: #0098df;
}
.footer_panel_left p,
.footer_panel_left p a {
  color: #8a92a6;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  transition: 0.2s all;
  margin-bottom: 0;
}
.top_filter_wrapper button.MuiButton-root {
  height: 40px;
}
.top_filter_wrapper button.MuiIconButton-root {
  color: #0098df;
  position: absolute;
  left: 0;
}
.top_filter_wrapper .search_input .MuiInputBase-root {
  padding-left: 30px;
}
.top_filter_wrapper .search_input.MuiFormControl-root label.MuiFormLabel-root {
  padding-left: 30px;
}
.top_filter_wrapper .search_input .MuiOutlinedInput-notchedOutline legend {
  font-size: 14px !important;
}
.total_trans_txt {
  color: #f5b300;
  margin-bottom: 0;
  margin-right: 50px;
}
.fiiled_filter .MuiFormLabel-root {
  color: #000 !important;
  top: -4px;
}
.fiiled_filter .MuiInputBase-root {
  background: linear-gradient(102deg,rgb(67 70 138) 34.09%,rgb(67 70 138) 71.72%) !important;
  color: #000;
  border-radius: 4px;
  height: 40px;
}
.fiiled_filter .MuiSvgIcon-root {
  color: #000;
}
.fiiled_filter .MuiInputBase-root::after,
.fiiled_filter .MuiInputBase-root::before {
  display: none;
}
.total_customer_txt {
  text-align: center;
  margin-bottom: 30px;
}
.total_customer_txt h4 {
  color: #fff;
  font-size: 28px;
  font-weight: 500;
  line-height: 130%;
  margin-bottom: 0;
}
.total_customer_txt p {
  color: #f5b300;
  font-size: 16px;
  font-weight: 400;
  line-height: 175%;
  margin-bottom: 0;
}
.category_add_form {
  display: flex;
  align-items: flex-end;
  gap: 15px;
  margin-bottom: 20px;
}
.category_add_form > div:first-child {
  width: 25%;
}
.category_add_form > div {
  width: 25%;
}
.category_add_form > div label {
  font-size: 16px;
  line-height: 130%;
  letter-spacing: 0.1px;
  display: block;
  margin-bottom: 5px;
}
.category_add_form > div .MuiFormControl-root {
  width: 100%;
}
.multiselect_placeholder {
  opacity: 0.4;
}
.user_create_switch {
  display: flex;
  gap: 10px;
  align-items: center;
}
.input-switch {
  display: none;
}
.label-switch {
  display: inline-block;
  position: relative;
  transition: 0.2s all;
  margin-bottom: -5px;
}
.label-switch::before,
.label-switch::after {
  content: "";
  display: inline-block;
  cursor: pointer;
  transition: all 0.5s;
}
.label-switch::before {
  width: 36px;
  height: 18px;
  border: 1px solid #757575;
  border-radius: 4em;
  background: #888888;
}
.label-switch::after {
  position: absolute;
  left: 3px;
  top: 2px;
  width: 14px;
  height: 14px;
  border: 1px solid #757575;
  border-radius: 4em;
  background: #ffffff;
}
.input-switch:checked ~ .label-switch::before {
  background: #f5b300;
  border-color: #f5b300;
}
.input-switch:checked ~ .label-switch::after {
  left: unset;
  right: 3px;
  background: #fff;
  border-color: #fff;
}
.info-text {
  display: inline-block;
  transition: 0.2s all;
  width: 68px;
  margin-right: 3px;
}
.info-text::before {
  content: " Inactive";
}
.input-switch:checked ~ .info-text::before {
  content: "Active";
}
.blog_add_form {
  margin-bottom: 25px;
}
.blog_add_form label {
  display: block;
}
.blog_add_form .MuiFormControl-root {
  width: 300px;
}
.primary_modal .modal-content {
  background: #191919;
}
.primary_modal .modal-header {
  border-bottom: 0;
}
.primary_modal .modal-header .modal-title {
  color: #caa626;
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
}
.primary_modal .modal-header .btn-close {
  filter: invert(1);
  box-shadow: none;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.primary_form .form-group {
  margin-bottom: 20px;
}
.primary_form .user_create_switch {
  justify-content: flex-end;
}
.primary_form .form-group label {
  display: block;
  margin-bottom: 5px;
}
.primary_modal
  .primary_form
  .custom_datepicker
  .react-datepicker__input-container
  input,
.primary_modal .primary_form .custom_datepicker .react-datepicker-wrapper {
  width: 100%;
}
.form-group.image_upload {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 0;
}
.form-group.image_upload label {
  margin-bottom: 0;
  color: #191919;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  padding: 2px 8px;
  margin-right: 10px;
}
.form-group.image_upload img {
  border-radius: 50%;
  width: 56px;
}
.change_password_panel .form-group {
  margin-bottom: 20px;
}
.change_password_panel img {
  width: 150px;
}
.change_password_panel label {
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: #fff;
  margin-bottom: 5px;
  text-transform: capitalize;
}
.change_password_panel input.form-control {
  border-radius: 10px;
  background: linear-gradient(102deg,rgba(67,70,138,.16) 34.09%,rgba(67,70,138,.03) 71.72%) !important;
  border: 1px solid #1a1d4e !important;
  color: #fff;
  box-shadow: none;
  padding: 15px 12px;
}
.change_password_panel h3 {
  color: #fff;
  font-size: 32px;
  font-weight: 400;
  line-height: normal;
  margin: 50px 0 10px;
}
.change_password_panel p {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.auth_wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.auth_main {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.auth_left {
  background: linear-gradient(112deg,rgba(6,8,52,.48) 1.31%,rgba(26,29,78,.5) 58.6%,rgba(2,7,57,.37) 112.43%);
  border: 1px solid #1a1d4e;
  border-radius: 20px;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.45);
  backdrop-filter: blur(20px);
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
}
.auth_right {
  text-align: center;
  width: 60%;
  height: 100%;
  display: flex;
}
.auth_right img {
  width: 350px;
  height: auto;
  margin: 0 auto;
}
.auth_left h2 {
  color: #fff;
  font-size: 40px;
  font-weight: 400;
  line-height: normal;
}
.auth_left p {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.auth_left label {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 5px;
}
.auth_form a {
  color: #999;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  text-align: right;
  display: block;
}
.auth_form input.form-control { 
  background: linear-gradient(102deg,rgba(67,70,138,.16) 34.09%,rgba(67,70,138,.03) 71.72%)!important;
  border: 1px solid #1a1d4e!important;
  border-radius: 10px;
  padding: 10px 15px;
  color: #fff;
  box-shadow: none !important;
}
.auth_form .form-group {
  margin-bottom: 20px;
}
/* .auth_main_forgot {
  background: #000;
  padding: 70px;
  justify-content: center;
} */
.auth_main_forgot .auth_left {
  width: 50%;
}
.auth_main_forgot .auth_left img {
  width: 150px;
  margin-bottom: 40px;
}
.auth_main_forgot .auth_left h2 {
  font-size: 32px;
}
.auth_left button {
  border-radius: 30px;
  padding: 10px 30px;
}
.google_auth_inp_group {
  border-radius: 4px;
  background: #161616 !important;
  border: 1px solid #161616 !important;
  display: flex;
  justify-content: space-evenly;
  margin-top: 50px;
}
.google_auth_inp_group input.form-control {
  width: 10%;
  border-bottom: 2px solid #fff !important;
  border-radius: 0;
  color: #fff;
  text-align: center;
}
.google_auth_logo {
  width: 220px;
  margin-right: 100px;
}
.app_sidebar_menu {
  height: 100%;
  overflow: auto;
  padding-bottom: 120px;
}
/* Chat Css Starts */
#frame {
  width: 100%;
  height: 92vh;
  min-height: 300px;
  max-height: 720px;
  display: flex;
  gap: 30px;
}
@media screen and (max-width: 360px) {
  #frame {
    width: 100%;
    height: 100vh;
  }
}
#frame #sidepanel {
  width: 40%;
  height: 100%;
  background: transparent;
  color: #f5f5f5;
  overflow: hidden;
  position: relative;
}
button.create_chat_mobile {
  display: none;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel {
    width: 65px;
    min-width: 65px;
  }
  button.create_chat_mobile {
    display: flex;
    width: 100%;
    margin: 15px 0 20px !important;
  }
}
#frame #sidepanel #profile {
  width: 100%;
  margin: 15px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile {
    width: 100%;
    margin: 0 auto;
    padding: 5px 0 0 0;
  }
}
#frame #sidepanel #profile.expanded .wrap {
  height: 210px;
  line-height: initial;
}
#frame #sidepanel #profile.expanded .wrap p {
  margin-top: 20px;
}
#frame #sidepanel #profile.expanded .wrap i.expand-button {
  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}
#frame #sidepanel #profile .wrap {
  height: 60px;
  line-height: 60px;
  overflow: hidden;
  -moz-transition: 0.3s height ease;
  -o-transition: 0.3s height ease;
  -webkit-transition: 0.3s height ease;
  transition: 0.3s height ease;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap {
    height: 55px;
  }
}
#frame #sidepanel #profile .wrap img {
  width: 50px;
  border-radius: 50%;
  padding: 3px;
  border: 2px solid #e74c3c;
  height: auto;
  float: left;
  cursor: pointer;
  -moz-transition: 0.3s border ease;
  -o-transition: 0.3s border ease;
  -webkit-transition: 0.3s border ease;
  transition: 0.3s border ease;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap img {
    width: 40px;
    margin-left: 4px;
  }
}
#frame #sidepanel #profile .wrap img.online {
  border: 2px solid #2ecc71;
}
#frame #sidepanel #profile .wrap img.away {
  border: 2px solid #f1c40f;
}
#frame #sidepanel #profile .wrap img.busy {
  border: 2px solid #e74c3c;
}
#frame #sidepanel #profile .wrap img.offline {
  border: 2px solid #95a5a6;
}
#frame #sidepanel #profile .wrap p {
  float: left;
  margin-left: 15px;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap p {
    display: none;
  }
}
#frame #sidepanel #profile .wrap i.expand-button {
  float: right;
  margin-top: 23px;
  font-size: 0.8em;
  cursor: pointer;
  color: #435f7a;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap i.expand-button {
    display: none;
  }
}
#frame #sidepanel #profile .wrap #status-options {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  width: 150px;
  margin: 70px 0 0 0;
  border-radius: 6px;
  z-index: 99;
  line-height: initial;
  background: #435f7a;
  -moz-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options {
    width: 58px;
    margin-top: 57px;
  }
}
#frame #sidepanel #profile .wrap #status-options.active {
  opacity: 1;
  visibility: visible;
  margin: 75px 0 0 0;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options.active {
    margin-top: 62px;
  }
}
#frame #sidepanel #profile .wrap #status-options:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 8px solid #435f7a;
  margin: -8px 0 0 24px;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options:before {
    margin-left: 23px;
  }
}
#frame #sidepanel #profile .wrap #status-options ul {
  overflow: hidden;
  border-radius: 6px;
}
#frame #sidepanel #profile .wrap #status-options ul li {
  padding: 15px 0 30px 18px;
  display: block;
  cursor: pointer;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options ul li {
    padding: 15px 0 35px 22px;
  }
}
#frame #sidepanel #profile .wrap #status-options ul li:hover {
  background: #496886;
}
#frame #sidepanel #profile .wrap #status-options ul li span.status-circle {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 5px 0 0 0;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options ul li span.status-circle {
    width: 14px;
    height: 14px;
  }
}
#frame
  #sidepanel
  #profile
  .wrap
  #status-options
  ul
  li
  span.status-circle:before {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  margin: -3px 0 0 -3px;
  background: transparent;
  border-radius: 50%;
  z-index: 0;
}
@media screen and (max-width: 735px) {
  #frame
    #sidepanel
    #profile
    .wrap
    #status-options
    ul
    li
    span.status-circle:before {
    height: 18px;
    width: 18px;
  }
}
#frame #sidepanel #profile .wrap #status-options ul li p {
  padding-left: 12px;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options ul li p {
    display: none;
  }
}
#frame
  #sidepanel
  #profile
  .wrap
  #status-options
  ul
  li#status-online
  span.status-circle {
  background: #2ecc71;
}
#frame
  #sidepanel
  #profile
  .wrap
  #status-options
  ul
  li#status-online.active
  span.status-circle:before {
  border: 1px solid #2ecc71;
}
#frame
  #sidepanel
  #profile
  .wrap
  #status-options
  ul
  li#status-away
  span.status-circle {
  background: #f1c40f;
}
#frame
  #sidepanel
  #profile
  .wrap
  #status-options
  ul
  li#status-away.active
  span.status-circle:before {
  border: 1px solid #f1c40f;
}
#frame
  #sidepanel
  #profile
  .wrap
  #status-options
  ul
  li#status-busy
  span.status-circle {
  background: #e74c3c;
}
#frame
  #sidepanel
  #profile
  .wrap
  #status-options
  ul
  li#status-busy.active
  span.status-circle:before {
  border: 1px solid #e74c3c;
}
#frame
  #sidepanel
  #profile
  .wrap
  #status-options
  ul
  li#status-offline
  span.status-circle {
  background: #95a5a6;
}
#frame
  #sidepanel
  #profile
  .wrap
  #status-options
  ul
  li#status-offline.active
  span.status-circle:before {
  border: 1px solid #95a5a6;
}
#frame #sidepanel #profile .wrap #expanded {
  padding: 100px 0 0 0;
  display: block;
  line-height: initial !important;
}
#frame #sidepanel #profile .wrap #expanded label {
  float: left;
  clear: both;
  margin: 0 8px 5px 0;
  padding: 5px 0;
}
#frame #sidepanel #profile .wrap #expanded input {
  border: none;
  margin-bottom: 6px;
  background: #32465a;
  border-radius: 3px;
  color: #f5f5f5;
  padding: 7px;
  width: calc(100% - 43px);
}
#frame #sidepanel #profile .wrap #expanded input:focus {
  outline: none;
  background: #435f7a;
}
#frame #sidepanel #search {
  font-weight: 300;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #search {
    display: none;
  }
}
#frame #sidepanel #search label {
  position: absolute;
  margin: 10px 0 0 20px;
}
#frame #sidepanel #search input {
  padding: 10px 0 10px 46px;
  width: 100%;
  border: none;
  background: #33323d;
  border-radius: 6px;
  color: #f5f5f5;
}
#frame #sidepanel #search input:focus {
  outline: none;
  background: #33323d;
}
#frame #sidepanel #search input::-webkit-input-placeholder {
  color: #f5f5f5;
}
#frame #sidepanel #search input::-moz-placeholder {
  color: #f5f5f5;
}
#frame #sidepanel #search input:-ms-input-placeholder {
  color: #f5f5f5;
}
#frame #sidepanel #search input:-moz-placeholder {
  color: #f5f5f5;
}
#frame #sidepanel #contacts {
  height: calc(100% - 177px);
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 15px;
  padding-right: 10px;
}
#frame #sidepanel #contacts ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts {
    height: calc(100% - 149px);
    overflow-y: scroll;
    overflow-x: hidden;
  }
  #frame #sidepanel #contacts::-webkit-scrollbar {
    display: none;
  }
}
#frame #sidepanel #contacts.expanded {
  height: calc(100% - 334px);
}
#frame #sidepanel #contacts::-webkit-scrollbar {
  width: 5px;
  background: #2c3e50;
}
#frame #sidepanel #contacts::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #b7bdcb;
  box-shadow: 1px 1px 50px 0px rgba(112, 124, 151, 0.15),
    2px 2px 10px 0px rgba(112, 124, 151, 0.1),
    4px 4px 15px 0px rgba(112, 124, 151, 0.05);
}
#frame #sidepanel #contacts ul li.contact {
  position: relative;
  padding: 10px 0 15px 0;
  font-size: 0.9em;
  cursor: pointer;
  border-radius: 6px;
  background: #1c1b22;
  margin-bottom: 15px;
}
#frame #sidepanel #contacts ul li.contact:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts ul li.contact {
    padding: 6px 0 46px 8px;
  }
}
#frame #sidepanel #contacts ul li.contact:hover {
  background: #33323d;
}
#frame #sidepanel #contacts ul li.contact.active {
  background: #33323d;
}
#frame #sidepanel #contacts ul li.contact.active span.contact-status {
  border: 2px solid #33323d !important;
}
#frame #sidepanel #contacts ul li.contact .wrap {
  width: 88%;
  margin: 0 auto;
  position: relative;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts ul li.contact .wrap {
    width: 100%;
  }
}
#frame #sidepanel #contacts ul li.contact .wrap span {
  position: absolute;
  left: 0;
  margin: -2px 0 0 -2px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #2c3e50;
  background: #95a5a6;
}
#frame #sidepanel #contacts ul li.contact .wrap span.online {
  background: #2ecc71;
}
.file_upload_msg {
  color: #f5b300;
  font-size: 14px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-top: 8px;
}
#frame #sidepanel #contacts ul li.contact .wrap span.away {
  background: #f1c40f;
}
#frame #sidepanel #contacts ul li.contact .wrap span.busy {
  background: #e74c3c;
}
#frame #sidepanel #contacts ul li.contact .wrap img {
  width: 40px;
  border-radius: 50%;
  float: left;
  margin-right: 10px;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts ul li.contact .wrap img {
    margin-right: 0px;
  }
}
#frame #sidepanel #contacts ul li.contact .wrap .meta {
  padding: 5px 0 0 0;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts ul li.contact .wrap .meta {
    display: none;
  }
}
#frame #sidepanel #contacts ul li.contact .wrap .meta .name {
  font-weight: 600;
}
#frame #sidepanel #contacts ul li.contact .wrap .meta .preview {
  margin: 5px 0 0 0;
  padding: 0 0 1px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -moz-transition: 1s all ease;
  -o-transition: 1s all ease;
  -webkit-transition: 1s all ease;
  transition: 1s all ease;
}
#frame #sidepanel #contacts ul li.contact .wrap .meta .preview span {
  position: initial;
  border-radius: initial;
  background: none;
  border: none;
  padding: 0 2px 0 0;
  margin: 0 0 0 1px;
  opacity: 0.5;
}
#frame #sidepanel #bottom-bar {
  position: absolute;
  width: 100%;
  bottom: 0;
}
#frame #sidepanel #bottom-bar button {
  float: left;
  border: none;
  width: 50%;
  padding: 10px 0;
  background: #32465a;
  color: #f5f5f5;
  cursor: pointer;
  font-size: 0.85em;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #bottom-bar button {
    float: none;
    width: 100%;
    padding: 15px 0;
  }
}
#frame #sidepanel #bottom-bar button:focus {
  outline: none;
}
#frame #sidepanel #bottom-bar button:nth-child(1) {
  border-right: 1px solid #2c3e50;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #bottom-bar button:nth-child(1) {
    border-right: none;
    border-bottom: 1px solid #2c3e50;
  }
}
#frame #sidepanel #bottom-bar button:hover {
  background: #435f7a;
}
#frame #sidepanel #bottom-bar button i {
  margin-right: 3px;
  font-size: 1em;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #bottom-bar button i {
    font-size: 1.3em;
  }
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #bottom-bar button span {
    display: none;
  }
}
#frame .content {
  width: 60%;
  height: 100%;
  overflow: hidden;
  background: #181717;
  position: relative;
}
#frame .content .contact-profile {
  width: 100%;
  height: 90px;
  background: #181717;
  border-bottom: 2px solid rgba(112, 124, 151, 0.1);
  display: flex;
  align-items: center;
}
#frame .content .contact-profile img {
  width: 48px;
  border-radius: 50%;
  margin: 9px 12px;
}
#frame .content .contact-profile p {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 0;
}
#frame .content .contact-profile small {
  color: #f5b300;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-transform: lowercase;
}
#frame .content .contact-profile .social-media {
  float: right;
}
#frame .content .contact-profile .social-media i {
  margin-left: 14px;
  cursor: pointer;
}
#frame .content .contact-profile .social-media i:nth-last-child(1) {
  margin-right: 20px;
}
#frame .content .contact-profile .social-media i:hover {
  color: #435f7a;
}
#frame .content .messages {
  height: auto;
  width: 100%;
  min-height: calc(100% - 200px);
  max-height: calc(100% - 200px);
  overflow-y: scroll;
  overflow-x: hidden;
}
/* @media screen and (max-width: 735px) {
  #frame .content .messages {
    max-height: calc(100% - 105px);
  }
} */
#frame .content .messages::-webkit-scrollbar {
  width: 5px;
  background: rgba(0, 0, 0, 0);
}
#frame .content .messages::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #b7bdcb;
  box-shadow: 1px 1px 50px 0px rgba(112, 124, 151, 0.15),
    2px 2px 10px 0px rgba(112, 124, 151, 0.1),
    4px 4px 15px 0px rgba(112, 124, 151, 0.05);
}
#frame .content .messages ul {
  padding: 0;
  list-style: none;
  margin: 0;
}
#frame .content .messages ul li {
  display: inline-block;
  clear: both;
  float: left;
  margin: 15px 15px 5px 15px;
  width: calc(100% - 25px);
  font-size: 0.9em;
}
#frame .content .messages ul li:nth-last-child(1) {
  margin-bottom: 20px;
}
#frame .content .messages ul li.sent img {
  margin: 6px 8px 0 0;
}
#frame .content .messages ul li.sent p {
  background: #33323d;
  color: #f5f5f5;
  border-radius: 0px 10px 10px 10px;
  box-shadow: 10px 10px 50px 0px rgba(42, 139, 242, 0.1),
    15px 15px 35px 0px rgba(42, 139, 242, 0.05),
    10px 10px 25px 0px rgba(42, 139, 242, 0.1);
}
#frame .content .messages ul li.replies img {
  float: right;
  margin: 6px 0 0 8px;
}
#frame .content .messages ul li.replies p {
  float: right;
  background: #33323d;
  border-radius: 10px 10px 0px 10px;
}
.contact_profile_detail_content p.name {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 0;
}
.contact_profile_detail_content small {
  color: #f5b300;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-transform: lowercase;
}
.contact_profile_detail {
  display: flex;
  align-items: center;
}
#frame .content .messages ul li img {
  width: 30px;
  border-radius: 50%;
  float: left;
}
#frame .content .messages ul li p {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 205px;
  margin-bottom: 0;
}
#frame .content .messages ul li p small {
  display: block;
  color: #a0a0a0;
}
#frame .content .messages ul li.sent p small {
  text-align: right;
}
#frame .content .messages ul li.replies p small {
  text-align: right;
}
#frame .chat_daily_divider {
  position: relative;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  text-transform: capitalize;
  text-align: center;
}
#frame .chat_daily_divider::before,
#frame .chat_daily_divider::after {
  content: "";
  background: rgba(112, 124, 151, 0.15);
  width: 38%;
  height: 1px;
  position: absolute;
  top: 15px;
}
#frame .chat_daily_divider::before {
  left: 0;
}
#frame .chat_daily_divider::after {
  right: 0;
}
@media screen and (min-width: 735px) {
  #frame .content .messages ul li p {
    max-width: 350px;
  }
}
#frame .content .message-input {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 99;
}
#frame .content .message-input .wrap {
  position: relative;
  border-top: 2px solid rgba(112, 124, 151, 0.15);
  padding: 10px 10px 10px 0;
  display: flex;
  align-items: center;
  background: #181717;
}
#frame .content .message-input .wrap input {
  border: none;
  width: 100%;
  padding: 5px 30px 5px 15px;
  font-size: 16px;
  color: #fff;
  background: #181717;
}
#frame .content .message-input .wrap button input[type="file"] {
  opacity: 0;
  position: absolute;
}
@media screen and (max-width: 735px) {
  #frame .content .message-input .wrap input {
    padding: 15px 32px 16px 8px;
  }
  #frame .content {
    width: 100%;
  }
  #frame {
    gap: 10px;
  }
}
#frame .content .message-input .wrap input:focus {
  outline: none;
}
#frame .content .message-input .wrap button {
  border: none;
  width: 36px;
  height: 36px;
  cursor: pointer;
  background: #f3ba2f;
  color: #181717;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
}
@media screen and (max-width: 735px) {
  #frame .content .message-input .wrap button {
    padding: 16px 0;
  }
}
#frame .content .message-input .wrap button:focus {
  outline: none;
}
.char_footer_btngrp {
  display: flex;
  gap: 5px;
}
@media screen and (max-width: 767px) {
  #frame #sidepanel #profile {
    display: none;
  }
  .contact_profile_detail_content {
    display: none;
  }
  .contact_profile_detail {
    display: block;
  }
}
@media screen and (max-width: 400px) {
  #frame .content .messages ul li p {
    max-width: 145px;
  }
}
/* Chat Css Ends */

.ylw_txt {
  color: #01fa00 !important;
}
.grey_txt {
  color: #adadad;
}
.round_btn {
  border-radius: 15px !important;
  padding: 7px 30px !important ;
  text-transform: capitalize !important ;
  background-color: #0098df !important;
}
.password-container {
  position: relative;
  display: flex;
}
.toggle-password {
  position: absolute;
  right: 10px; /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.label_chg {
  overflow: hidden;
}
.label_chg .apexcharts-align-center {
  flex-direction: column;
  gap: 5px;
}
.bgcolor button {
  background: #111111;
  z-index: 1;
  padding: 4px;
}
.admin_profile {
  text-overflow: unset !important;
}
/* Media Queries */
@media screen and (max-width: 1479px) {
  .market_panel {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .widget_panel {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
@media screen and (max-width: 1399px) {
  .total_fund_value h3 {
    font-size: 24px;
  }
}
@media screen and (max-width: 1199px) {
  .topnavbar_right i.fa-bars {
    display: block;
    font-size: 20px;
  }
  .app_sidebar {
    left: -250px;
    transition: left 0.3s ease;
  }
  .page_wrapper {
    margin-left: 0;
  }
  .app_header {
    left: 0;
  }
  .app_sidebar.show {
    left: 0;
  }
  body.menu_overlay {
    overflow: hidden;
    position: relative;
  }
  body.menu_overlay::before {
    content: "";
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 101;
  }
  .app_sidebar i.close_icon {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 24px;
    display: block;
  }
  .auth_main {
    flex-direction: column;
    justify-content: center;
    gap: 30px;
  }
  .google_auth_logo {
    margin-right: 0;
    width: 180px;
  }
}
@media screen and (max-width: 991px) {
  .dashboard_page [class*="col-"] {
    margin-bottom: 15px;
  }
  .dashboard_page .py-2 {
    padding: 0 !important;
  }
  .widget_panel {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .top_filter_wrapper {
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
  }
  .custom_datepicker .react-datepicker__input-container input,
  .top_filter_wrapper .form-group {
    width: 100%;
  }
  .custom_datepicker .react-datepicker-wrapper {
    width: 100%;
  }
  .top_filter_wrapper .MuiFormControl-root {
    width: 100%;
  }
  .top_filter_wrapper .form-group {
    margin-bottom: 0 !important;
  }
  .category_add_form {
    flex-direction: column;
    align-items: flex-start;
  }
  .category_add_form > div:first-child,
  .category_add_form > div {
    width: 100%;
  }
  .blog_add_form {
    margin-bottom: 0;
    width: 100%;
  }
  .auth_right {
    display: none;
  }
  .auth_left {
    width: 100%;
  }
  .auth_main_forgot .auth_left {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .widget_panel {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .market_panel {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .top_filter_wrapper .MuiFormControl-root,
  .top_filter_wrapper .MuiButton-root,
  .dashbox_inner .search_input {
    width: 100%;
  }
}
@media screen and (max-width: 575px) {
  .widget_panel {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .market_panel {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 10px;
  }
  .total_visits_panel {
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
  }
  .dashtitle_flex {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .usericon_dropdown span {
    display: none;
  }
  h2.page_title {
    font-size: 20px;
  }
  .topnavbar_right {
    gap: 15px;
  }
  .topnavbar_right .dropdown-toggle::after {
    margin-left: 10px;
  }
  .page_content .container-fluid,
  .app_header .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  .footer_panel {
    flex-direction: column;
    gap: 8px;
  }
  .auth_left {
    padding: 30px 15px;
  }
  .auth_left h2,
  .auth_main_forgot .auth_left h2 {
    font-size: 28px;
  }
  .auth_main_forgot {
    padding: 10px;
  }
  .change_password_panel img {
    width: 100px;
  }
}
